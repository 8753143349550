export { default as IconDashboard } from './Dashboard'
export { default as IconEquipment } from './Equipment'
export { default as IconLight } from './Light'
export { default as IconMaintain } from './Maintain'
export { default as IconMaterial } from './Material'
export { default as IconPerformance } from './Performance'
export { default as IconReport } from './Report'
export { default as IconReview } from './Review'
export { default as IconSystem } from './System'
export { default as IconAuthority } from './Authority'
export { default as IconEye } from './Eye'
export { default as IconLocation } from './Location'
export { default as IconAdd } from './Add'
export { default as IconChecked } from './Checked'
export { default as IconCamera } from './Camera'
export { default as IconEdit } from './Edit'
export { default as IconMinusCircle } from './MinusCircle'
export { default as IconAddCircle } from './AddCircle'
export { default as IconLeftOutlined } from './LeftOutlined'
export { default as IconPlay } from './Play'
export { default as IconPrev } from './Prev'
export { default as IconNext } from './Next'
export { default as IconLock } from './Lock'
export { default as IconPermission } from './Permission'
export { default as IconMap } from './Map'
export { default as IconExport } from './Export'
export { default as IconMapHome } from './MapHome'
export { default as IconCross } from './Cross'
export { default as IconHome } from './Dashboard'
export { default as IconUser } from './User'
export { default as IconDevice } from './Equipment'
export { default as IconSetting } from './Maintain'
export { default as IconSearch } from './Search'
export { default as IconGroup } from './Group'
export { default as IconSuccess } from './MessageSuccess'
export { default as IconFail } from './MessageFail'
export { default as IconError } from './MessageError'
export { default as IconMedia } from './Media'
export { default as IconClose } from './Close'
export { default as IconList } from './List'
export { default as IconCate1 } from './Cate1'
export { default as IconCate2 } from './Cate2'
export { default as IconCate3 } from './Cate3'
export { default as IconCate4 } from './Cate4'
export { default as IconCate5 } from './Cate5'
export { default as IconCate6 } from './Cate6'
export { default as IconDatabase } from './Database'
export { default as IconClipboard } from './Clipboard'
export { default as IconBook } from './Book'
export { default as IconHistory } from './History'
export { default as IconStatic } from './Static'
export { default as IconApproval } from './Approval'
export { default as IconImport } from './Import'
export { default as IconDownload } from './Download'
export { default as IconFilter } from './Filter'
export { default as IconLoginUser } from './LoginUser'
export { default as IconFolder } from './Folder'
export { default as IconFolderWhite } from './FolderWhite'
export { default as IconFolderBlack } from './FolderBlack'
export { default as IconCustomer } from './Customer'
export { default as IconListTransparent } from './ListTransparent'
export { default as IconCalc } from './Calc'
export { default as IconCompany } from './Company'
export { default as IconGreenPower } from './GreenPower'
export { default as IconEvaluate } from './Evaluate'
export { default as IconAlert } from './Alert'
export { default as IconCompanyList } from './CompanyList'
export { default as IconRocket } from './Rocket'
