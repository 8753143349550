import i18n from 'i18next'

export type IReportUnit = {
  label: React.ReactNode
  value: number
}

export const POWER_UNITS: IReportUnit[] = [
  {
    label: i18n.t('report:kWh'),
    value: 1,
  },
  {
    label: i18n.t('report:mWh'),
    value: 0.001,
  },
]

export const FUEL_UNITS: IReportUnit[] = [
  {
    label: i18n.t('report:liter'),
    value: 1,
  },
  {
    label: i18n.t('report:kiloliter'),
    value: 0.001,
  },
]

export const SCRAP_UNITS: IReportUnit[] = [
  {
    label: i18n.t('report:kg'),
    value: 1,
  },
  {
    label: i18n.t('report:ton'),
    value: 0.001,
  },
]
