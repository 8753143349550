/**
 * 最新版 V2 style
 */
import { mergeDeepRight } from 'ramda'
import { lighten, darken, transparentize } from 'polished'
import themeV1 from './themeV1'

const secondary = themeV1.secondary
const info = themeV1.info
const darkGrey = themeV1.darkGrey
const darkBlue = themeV1.darkBlue
const whisper = themeV1.whisper

const green = themeV1.green

/**
 * @return { [key: string]: any}
 */
function createThemeConfig(config) {
  const baseConfig = {
    secondary: secondary,
    success: green,
    warning: '#2c2c2c',
    error: '#ff0000',
    info: info,
    darkBlue,
    // 清單列表
    table: {
      disabledRow: '#e7e7e7',
      headBg: 'transparent',
      headText: '#878787',
      shape: 'none',
      bg: '#fff',
      bgHover: '#fffcf1',
      border: whisper,
      borderBottom: darkGrey,
      borderBottomHover: '#5e9ce5',
      borderHover: 'transparent',
      spacing: 0,
      text: '#4a4a4a',
    },
    report: {
      title: '#073963',
    },
    menuInlineTooltip: {
      bg: '#fff',
      color: "#878787",
      active:"#878787",
    },
  }

  return { ...baseConfig, ...config }
}

// 共用
const base = {
  danger: '#E17567',
}
// 藍板
const normal = {
  primary: '#4481EB',
  background: '#F0F0F0',
}
// 綠板
const admin = {
  primary: '#53B55C',
  background: '#F0F0F0',
}

const normalConfig = createThemeConfig({
  loading: {
    color: '#0075d3',
  },
  primary: normal.primary,
  lightenPrimary: lighten(0.2, normal.primary),
  background: normal.background,
  containerBg: normal.background,
  pagination: normal.primary,
  checkbox: {
    border: normal.primary,
    bg: normal.primary,
    text: normal.primary,
    color: '#fff',
    indeterminate: normal.primary,
  },
  modal: {
    title: normal.primary,
    close: {
      color: normal.primary,
      hover: lighten(0.2, normal.primary),
    },
  },
  btn: {
    text: '#fff',
    bg: normal.primary,
    bgHover: darken(0.2, normal.primary),
    // 套用所有 button 樣式
    shadow: 'none',
    ghost: {
      text: normal.primary,
      border: normal.primary,
      hover: transparentize(0.8, normal.primary),
    },
    danger: {
      text: base.danger,
      border: base.danger,
      hover: transparentize(0.8, base.danger),
    },
    secondary: {
      border: normal.primary,
      bg: normal.primary,
      borderRadius: '16px',
      text: '#fff',
      hoverBorder: darken(0.2, normal.primary),
      hoverBg: darken(0.2, normal.primary),
      icon: {
        color: '#fff',
      },
    },
    third: {
      bg: 'rgb(201, 227, 248)',
      border: 'rgb(112, 112, 112)',
      text: '#000',
      hover: {
        bg: 'rgba(201, 227, 248, 0.5)',
        border: 'rgb(120, 148, 171)',
        text: '#000',
      },
    },
    linkHover: '#40a9ff',
    linkColor: '#1890ff',
    info: info,
    infoHover: 'rgba(94, 156, 229, 0.5)',
    border: normal.primary,
  },
  menu: {
    boxShadow: '0px 2px 10px 0px #0000001a',
    borderRadius: '0 17px 17px 0',
    text: '#878787',
    // 套用 menu 區塊的顏色
    bg: normal.background,
    main: {
      text: '#878787',
      selected: {
        text: '#fff',
        bg: normal.primary,
      },
      hover: {
        text: '#878787',
        bg: lighten(0.2, normal.primary),
      },
    },
    // 子菜單
    sub: {
      text: '#878787',
      selected: {
        text: normal.primary,
        bg: transparentize(0.9, normal.primary),
      },
      hover: {
        text: normal.primary,
        bg: transparentize(0.9, normal.primary),
      },
    },
  },
})

const adminConfig = createThemeConfig({
  loading: {
    color: '#0075d3',
  },
  primary: admin.primary,
  lightenPrimary: lighten(0.2, admin.primary),
  pagination: admin.primary,
  background: admin.background,
  containerBg: admin.background,
  checkbox: {
    border: admin.primary,
    bg: admin.primary,
    text: admin.primary,
    color: '#fff',
    indeterminate: admin.primary,
  },
  modal: {
    title: admin.primary,
    close: {
      color: admin.primary,
      hover: lighten(0.2, admin.primary),
    },
  },
  btn: {
    text: '#fff',
    bg: admin.primary,
    bgHover: darken(0.2, admin.primary),
    // 套用所有 button 樣式
    shadow: 'none',
    ghost: {
      text: admin.primary,
      border: admin.primary,
      hover: transparentize(0.8, admin.primary),
    },
    danger: {
      text: base.danger,
      border: base.danger,
      hover: transparentize(0.8, base.danger),
    },
    secondary: {
      border: admin.primary,
      bg: admin.primary,
      borderRadius: '16px',
      text: '#fff',
      hoverBorder: darken(0.2, admin.primary),
      hoverBg: darken(0.2, admin.primary),
      icon: {
        color: '#fff',
      },
    },

    linkHover: '#40a9ff',
    linkColor: '#1890ff',
    info: info,
    infoHover: 'rgba(94, 156, 229, 0.5)',
    border: admin.primary,
  },
  menu: {
    boxShadow: '0px 2px 10px 0px #0000001a',
    borderRadius: '0 17px 17px 0',
    text: '#878787',
    // 套用 menu 區塊的顏色
    bg: admin.background,
    main: {
      text: '#878787',
      selected: {
        text: '#fff',
        bg: admin.primary,
      },
      hover: {
        text: '#878787',
        bg: lighten(0.2, admin.primary),
      },
    },
    // 子菜單
    sub: {
      text: '#878787',
      selected: {
        text: admin.primary,
        bg: transparentize(0.9, admin.primary),
      },
      hover: {
        text: admin.primary,
        bg: transparentize(0.9, admin.primary),
      },
    },
  },
})

export const normalTheme = mergeDeepRight(themeV1, normalConfig)
export const adminTheme = mergeDeepRight(themeV1, adminConfig)
export const baseTheme = { normalTheme, adminTheme }

export default themeV1
